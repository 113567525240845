<template>
    <div class="home">
      <Header/>
      <div class="content"> 
        <div class="top">
            <div class="title">Mswap</div>
            <div class="price">{{$t("m.bj")}}：${{price}}</div>
        </div>
     <div class="card">
        <div class="cont">
          <div class="input">
            <input type="text" class="yinput" placeholder="0.0" v-model="snum" @input="jsPrice" @blur="jsPrice">
            <div class=""  >
            <div class="dw" >
             <div class="tt" >MICO</div>  
            <img :src="require('../assets/img/slogo.png')" alt="usdt" class="ico" >
            </div>           
        </div>
          </div>
          <div class="yue">{{$t('m.qbye')}}：{{micoNum||0}}</div>
          <div class="linebox">
            <div class="sitem" :class="flee==25?'act':''" @click="tochanger(25)">25%</div>
            <div class="sitem" :class="flee==50?'act':''" @click="tochanger(50)">50%</div>
            <div class="sitem" :class="flee==75?'act':''" @click="tochanger(75)">75%</div>
            <div class="sitem" :class="flee==100?'act':''" @click="tochanger(100)">100%</div>
          </div>
          <div class="dh">
            <img src="../assets/img/dh.png" alt="dh" class="img">
          </div>

          <div class="input">
            <input type="text" class="yinput" placeholder="0.0" v-model="pnum" readonly>
            <div class=""  >
            <div class="dw" >
             <div class="tt" >USDT</div>  
            <img :src="require('../assets/img/usdt.png')" alt="usdt" class="ico" >
            </div>
             </div>
          </div>
          <div class="yue">{{$t('m.qbye')}}：{{usdtNum||0}}</div>
        
        </div>
        <div class="buttom">
            <van-button
          :loading="loading"
          type="primary"
          :class="pnum>0?'act':''"
          class="btn"  
          @click="toExchengs">
          
          Swap</van-button>
        </div>
     </div>
    </div>

    <van-popup v-model="show" round :close-on-click-overlay="false" >
      <div class="popup ">
        <div class="top"> <div class="title">选择一个代币</div>
        <img src="../assets/img/gbs.png" alt="" class="close" @click="show=false"/>
    </div>
       <div class="bzlbox">
        <div class="sbox" v-for="(item,i) in slist" :key="i" @click="toxz(item)">
            <img :src="item.img" alt="" class="ico">
            <div class="name">{{item.name}}</div>
        </div>       
       </div>     
        <div class="line"></div>
       <div class="list">
            <div class="sitem" v-for="(item,i) in list" :key="i" @click="toxz(item)">
                <div class="icon">
                    <img :src="item.img" alt="" class="ico">
                </div>
                <div class="cont">
                    <div class="name">{{item.name}}</div>
                    <div class="ttx">{{item.tips}}</div>
                </div>
                <div class="num">{{item.num}}</div>
            </div>
       </div>
      </div>
    </van-popup>
    <van-popup v-model="shows" round :close-on-click-overlay="true" >
      <div class="popup ">
        <div class="top"> 
        <div class="title">最大滑点</div></div>
        <div class="linex">
            <div class="item" @click="toFie(0.1)">0.1%</div>
            <div class="item" @click="toFie(0.5)">0.5%</div>
            <div class="item" @click="toFie(1)">1.0%</div>
            <div class="sitem"><input type="text" v-model="fbl" class="input" placeholder="0"><div class="dw">%</div></div>
        </div>
        <div class="line"></div>
        <div class="linex">
            <div class="txt">交易截止时间</div>
            <div class="sitem"><input type="text" v-model="time" class="input" placeholder="0"><div class="dw">分钟</div></div>
        </div>
        <div class="tips" v-if="fbl==0.1"><van-icon name="warning" color="#F1BF20"/><div class="ttx">你的交易可能会失败</div></div>
        
      </div>
    </van-popup>

    <Bottom/>
</div>
</template>
<script>
import Header from '../components/header.vue';
import Bottom from '../components/bottom.vue';
import {getPrice,toExChange,getMyMc,getMyUsdt,toApprove} from "@/api/Public";
export default {
  name: "myContracts",
  components: {Header,Bottom},
  data() {
    return {
        current:0,
        flee:0,
        flees:0,
        price:0,
        snum:"",
        pnum:"",
        xuze:true,
        show:false,
        shows:false,
        fbl:0.2,
        time:20,
        loading:false,
        info:"",
        infos:"",
        type:1,
        micoNum:0,
        usdtNum:0,
        slist:[
        {img:require('../assets/img/bnb.png'),
            name:"BNB",
            tips:"BNB",
            num:0},
            {img:require('../assets/img/slogo.png'),
            name:"ARCT",
            tips:"ARCT Token",
            num:0},
        ],
        list:[
            {img:require('../assets/img/bnb.png'),
            name:"BNB",
            tips:"BNB",
            num:0},
            {img:require('../assets/img/usdt.png'),
            name:"USDT",
            tips:"Binance-Peg BSC-USD",
            num:0},
            {img:require('../assets/img/slogo.png'),
            name:"ARCT",
            tips:"ARCT Token",
            num:0},
            {img:require('../assets/img/btc.png'),
            name:"BTC",
            tips:"Binance-Peg BTCB Token",
            num:0},
            {img:require('../assets/img/eth.png'),
            name:"ETH",
            tips:"Binance-Peg Ethereum Token",
            num:0},
        ]

    }
  },
  created(){
    this.userWallet = this.$store.getters.userWallet;
    this.getMyAmount()
    this.getPrice()
  },
  methods:{
    getMyAmount(){
        getMyMc(this.$store.getters.userWallet).then(res=>{
            this.micoNum=Math.round(res*1000000)/1000000
        })
        getMyUsdt(this.$store.getters.userWallet).then(res=>{
            this.usdtNum=Math.round(res*1000000)/1000000
        })
    },
    
    toExchengs(){
        if(this.pnum>0){
            toApprove(this.$store.getters.userWallet,this.snum).then(res=>{
                if(res){
                    toExChange(this.$store.getters.userWallet,this.snum).then(res=>{
            if(res){
                this.snum=""
                this.pnum=""
                this.$notify({
                message:this.$i18n.t("m.dhcg") ,
                type: "success",
              });
                this.getMyAmount()
                this.getPrice()
            }else{
                this.$notify({
                message: this.$i18n.t("m.dhsb"),
                type: "danger",
              });
            }
        })
                }else{
                    this.$notify({
                message: this.$i18n.t("m.dhsb"),
                type: "danger",
              });
                }
            })
       
    }
    },
    getPrice(){
        getPrice().then(res=>{
            this.price=Math.round(res*1000000)/1000000
        }) 
    },
    changer(e){
        this.current=e
    },
    toFie(e){
        this.fbl=e
    },
    tochanger(e){
        this.flee=e
        this.snum=Math.round(this.micoNum*e*1000000)/100000000
       this.jsPrice()
    },
    jsPrice(){
        setTimeout(() => {
            this.snum=this.micoNum-this.snum>0?this.snum:this.micoNum
            this.pnum=Math.round(this.snum*this.price*100000000)/100000000
        }, 100);
       
    },
    tochangers(e){
        this.flees=e
    },
    toShow(e){
        this.type=e
        this.show=true
    },
    toxz(e){
        if(this.type==1){this.info=e}
        if(this.type==2){this.infos=e}
        this.show=false
    },
  }
}

</script>
<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  // background: #161628;
  background-size:contain;
  background-position: center bottom;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
    .content{
        margin-top: 188px;
        .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:0 48px;
            .title{
            font-size: 48px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
        }
        .price{
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
        }
            .ico{
                width: 40px;
                height: 40px;
            }
        }
      
       .card{
        display: flex;
    flex-direction: column;
    border-radius: 30px;
    border: 1px solid rgb(53, 53, 53);
    background: linear-gradient(135.46deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%);
    position: relative;
    padding: 50px 30px;
    margin: 48px;
    .cont{
        padding: 24px 0 40px;
        border-bottom: 1px solid #2A2A2A;
        .input{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: #2A2A2A;
            border-radius: 100px;
            height: 86px;
            padding: 8px 30px 8px 30px;
            box-sizing: border-box;     
            margin: 32px 0 0;   
            overflow: hidden;    
            .yinput{
              border: none;
              background: transparent;
              color: #ffffff;
              font-size: 32px;
              font-weight: bold;
              width: 100%;
            }
            .dw{
              display: flex;
              align-items: center;
              .tt{
                color: #ffffff;
                font-size: 28px;
                font-weight: bold;
                margin:  0 10px;                
                white-space: nowrap;
              }
              .ico{
                width: 40px;
                height: 40px;
                margin-right: 8px;
              }
            }
          }
          .yue{
            font-size: 24px;
            color: #ABABAB;
            margin-top: 20px;
            text-align: left;
            width: 100%;
          }
          .linebox{
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .sitem{
                padding: 10px 20px;
                border-radius: 30px;
                text-align: center;
                color: #ffffff;
                font-size: 26px;
                font-weight: 600;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 140px;
                box-sizing: border-box;
                border: 1px solid transparent;
                background: linear-gradient(135.46deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%);
            }
            .act{
            background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
            border: 1px solid #ffffff;
            }
          }
          .dh{
            margin: 40px auto;
            .img{
                width: 88px;
            }
          }
    }
    .buttom{
        display: flex;
        width: 100%;    
        margin-top: 30px;  
        .btn{
         background: #2A2A2A;
          color: #ABABAB;
          border: none;
          border-radius: 100px;
          height: 80px;
          width: 100%;
          font-size: 24px;
        }
        .act{
          color: #ffffff;
          background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
        }
      }
       }
       
    }
   
    .popup{
        width: 672px;
        height: auto;
        background: #ffffff;
        padding: 30px 30px 70px;
        box-sizing: border-box;
        .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
            .close{
                width:28px;

            }
        }
        .title{
            width: 100%;
            font-size: 32px;
            color: #000000;
            text-align: left;
           
        }
        .line{
            width: 100%;
            height: 1px;
            background: #e9e9e9;
            margin: 40px 0;
        }
        .linex{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            .item{
                color: #E167FF;
                font-size: 24px;
                background: rgb(245, 245, 245);
                width: 120px;
                padding: 8px 16px;
                border-radius: 56px;
                box-sizing: border-box;
                font-weight: bold;
            }
            .sitem{
                display: flex;
                align-items: center;
                .input{
                    width: 120px;
                    border-radius: 56px;
                    border: none;
                    padding: 8px 16px;
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px inset;
                    background:rgb(245, 245, 245) !important;
                    text-align: center;
                    font-weight: bold;
                }
                .dw{
                    font-size: 28px;
                    color: #E167FF;
                    font-weight: bold;
                    margin-left: 8px;
                }
            }
            .txt{
                font-weight: 400;
                color: #000000;
                font-size: 28px;
            }
        }
        .tips{
            color: #F1BF20;
            border:1px solid #F1BF20;
            background: #FFF4E5;
            margin-top: 36px;
            border-radius: 12px;
            font-size: 24px;
            padding: 10px 16px;
            display: flex;
            align-items: center;
            .ttx{
                margin-left: 10px;
            }
        }

        .bzlbox{
            display: flex;
            align-items: center;
            .sbox{
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f5f5f5;
                border-radius: 58px;
                width: 162px;
                height: 60px;
                cursor: pointer;
                margin-right: 36px;
                .ico{
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    margin-right: 24px;
                }
                .name{
                    color: #000000;
                    font-weight: 500;
                    font-size: 28px;
                }
            }
        }
        .list{
            .sitem{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin: 48px 26px;
                .icon{
                    width:60px;
                    height:60px;
                    margin-right: 28px;
                    .ico{
                        width: 60px;
                        height: 60px;
                        border-radius: 100%;
                        box-shadow: rgba(0, 0, 0, 0.075) 0px 6px 10px;
                    }
                }
                .cont{
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    align-items: flex-start;
                    .name{
                        font-size: 32px;
                        font-weight: bold;
                        line-height: 40px;
                    }
                    .ttx{
                        font-size: 24px;
                        color: #E167FF;
                    }
                }
                .num{
                    font-size: 32px;
                    color: #000000;
                    font-weight: bold;
                }
            }
        }
    }
}

  </style>
