import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, 
   {
    path: '/myContracts',
    name: 'myContracts',
    component: () => import('@/views/myContracts.vue')
   },
   {
    path: '/myCommunity',
    name: 'myCommunity',
    component: () => import('@/views/myCommunity.vue')
   },
   
   {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news.vue')
   },
   {
    path: '/consensusNode',
    name: 'consensusNode',
    component: () => import('@/views/consensusNode.vue')
   },
     
  {     
     path: '/partner',
    name: 'partner',
    component: () => import('@/views/partner.vue')
  },
 
 {
     
  path: '/detail',
 name: 'detail',
 component: () => import('@/views/detail.vue')
},
{
     
  path: '/swap',
 name: 'swap',
 component: () => import('@/views/swap.vue')
},
 



   
  

]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //设置回到顶部
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router
