export const m = 
{ 
  lqlj: "确定领取",
  sqinfo: "要使用您个人账户的功能，请使用钱包授权",
  sqbtn: "钱包授权",
  sqload: "授权中...",
  dltt: "登录到您的账户",
  dlinfo: "要使用您个人账户的功能，请点击链接钱包",
  dlbtn: "链接钱包",
  bdtt: "绑定上级",
  bdinfo: "请输入推荐人钱包地址",
  bdbtn: "确认绑定",
  bdload: "绑定中...",
  bangsuc:"绑定成功",
  lqsg: "领取成功",
  lqsb: "领取失败",
  day: "天",
  shi:"时",
  fen:"分",
  miao:"秒",
  fzcg:"复制成功",
  fzsb:"复制失败",
  yhdj:"用户等级",
  zwkf:"提示：暂未开放！",
  mysj:"没有数据",
  wdyqlj:"我的邀请链接",
  wdtjdz:"我的推荐地址",
  fz:"复制",
  home:"首页",
  qr:"确认",
  ny:"您有",
  wcqbqr:"完成钱包确认",
  dqsl:"授权数量",
  cyje:"参与金额",
  qsrcyje:"请输入参与金额",
  zfsl:"支付数量",
  qbye:"钱包余额",
  ljcy:"立即参与",
  wdzc:"我的资产",
  klqzc:"可领取资产",
  jrsy:"今日收益",
  ljsy:"累计收益",
  lq:"领取",
  qrlq:"确认领取",

  wddd:'我的订单',
  yxz:"运行中",
  ycj:"已出局",
  cysj:"参与时间",
  zfje:"支付金额",
  cjjd:"出局节点",

  wdsq:"我的社区",
  wddj:"我的等级",
  sfjd:"是否是节点",
  shi:"是",
  fou:"否",
  kgdj:"矿工等级",
  wu:"无",
  cjkg:"初级矿工",
  zjkg:"中级矿工",
  gjkg:"高级矿工",
  cjkgs:"超级矿工",
  cskg:"创世矿工",
  slzx:"算力中心",
  grztslz:"个人在投算力值",
  grztrslz:"个人总投入算力值",
  ydztslz:"一代在投算力值",
  erztslz:"二代在投算力值",
  xqztslz:"小区在投算力值",
  sqztslz:"社区在投算力值",
  wdfx:"我的分享",
  wxyh:"无效用户",
  yxyh:"有效用户",
  cycg:"参与成功",
  cysb:"参与失败",
  lyjh:"链游聚合",
kfz:"开发中...",
gglb:"公告列表",
ggtt:"MICO震撼上线！",
ggnr:"热烈庆祝全球首个链游聚合平台，正式上线，正式开启token算力吸粉1.0时代，一起打开链游元宇宙的财富宝库。",
dhcg:"兑换成功",
dhsb:"兑换失败",
bj:"币价",
zytz:"重要通知",
wgxlk:"為感謝新老玩家對平臺的支持和信任，為感謝玩家們一直以來對平臺的寬容和厚愛。恰逢大陸新年即將來臨，平臺為共識者發放福利，提前預祝大陸社區新年快樂，吉祥如意.",
jrq:"即日起區塊將提升400000token每日暴塊分發算力.新年結束，區塊恢復200000token每日.",
syyz:"MICO運營組"














}